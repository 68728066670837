/*
 * @Author: your name
 * @Date: 2021-03-21 10:42:33
 * @LastEditTime: 2021-06-07 21:04:35
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\api\common\index.js
 */
import request from '@/utils/request'
import Api from '@/api/base/api-config'

// 获取省市数据树
export const getAreaTree = () => {
  return request({
    method: 'GET',
    url: Api.common.getAreaTree
  })
}

// Customer Profile主数据模板查询
export const customerprofile = () => {
  return request({
    method: 'GET',
    url: Api.common.customerprofile
  })
}

// 获取动向
export const trendnew = data => {
  return request({
    url: `${Api.common.trendnew}?accountId=${data.accountId}`,
    method: 'get'
  })
}

// 获取省市数据树
export const aliyunOSS = () => {
  return request({
    method: 'GET',
    url: Api.common.aliyunOSS
  })
}
